.header {
  margin-bottom: 16px;

  &__wrapper {
    border: 1px solid $dark-blue;

    img {
      width: 100%;
    }
  }

  &__text {
    font-size: 16px;
    text-align: center;
    color: $black;
    padding: 8px 0;
  }
}