@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  background-color: #EEE;
  div, p, h1, h2, label {
    font-family: Verdana, Arial, Helvetica, sans-serif;
  }

  p {
    font-size: 12px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  // colors slate editor
  .red {
    color: red;
  }

  .blue {
    color: blue;
  }

  .marker {
    background-color: yellow;
  }
}