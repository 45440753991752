.box-contenuto {
  font-size: 12px;
  margin-bottom: 16px;

  &--large {
    .box-contenuto__wrapper {
      padding: 70px 0;

      h1 {
        font-size: 16px;
        margin: 0;
      }
    }
  }

  &__wrapper {
    border: 1px solid $dark-blue;
    padding: 2px 0;

    p {
      margin: 0;
    }

    &--yellow {
      background-color: rgb(255, 255, 204);

      .event-table {
        th {
          border: 2px solid rgb(255, 255, 204);
        }
      }
    }
  }

  &__text-gray {
    background-color: $gray;
    padding: 0 16px;
    margin-top: 16px;
  }

  a {
    font-size: 12px;
    font-weight: bold;
    color: $red;

    &:hover {
      color: $red;
    }
  }

  &__title {
    font-weight: bold;
    color: $dark-red;
    padding: 6px 0;
  }

  &__btn {
    background-color: transparent;
    border: none;
    outline: none;
    color: $red;
    font-weight: bold;

    &:hover, &:focus {
      text-decoration: underline;
      outline: none;
    }
  }

  ol {
    padding-left: 22px;
  }
}