.slate {
  width: 600px;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  text-align: left;
  margin-bottom: 6px;
  background-color: $white;

  &.focused {
    outline: 1px solid black;
  }

  .toolbar {
    border-bottom: 2px solid rgb(238, 238, 238);
    color: rgb(204, 204, 204);
    padding: 7px 12px 4px 12px;

    button {
      border: none;
      background-color: transparent;
      color: rgb(204, 204, 204);
      outline: none;
      margin: 0;
      padding: 0;
      margin-left: 6px;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        color: $black;
      }

      .material-icons {
        font-size: 18px;
        vertical-align: text-bottom;
      }
    }

    select {
      margin-left: 6px;
    }
  }

  .text-area {
    padding: 12px;
    border-top-color: transparent;

    &__editor {
      min-height: 180px;
    }
  }

  span {
    .red {
      color: red;
    }
    .blue {
      color: blue;
    }
    .marker {
      background-color: yellow;
    }
  }
}