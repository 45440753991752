.navigation {
  margin-bottom: 16px;
  
  &__list {
    border: 1px solid $dark-blue;
    list-style: none;
    margin: 0;
    padding: 2px 0;
  }

  &__link {
    display: block;
    border: none;
    background-color: transparent;
    font-size: 12px;
    font-weight: bold;
    color: $red;

    &:hover, &:focus {
      text-decoration: underline;
      color: $red;
      border: none;
      outline: none;
    }
  }

}