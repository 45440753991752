.event-table {
  width: 100%;
  border-collapse: collapse;
  box-sizing: border-box;
  border-spacing: 2px;

  th {
    background-color: $gray;
    border: 2px solid white;
  }

  td {
    vertical-align: top;
    padding: 6px 0;

    p {
      margin-bottom: 1em;
    }
  }

  tr {
    border-bottom: 1px solid $gray;
    padding: 10px 0;
    
    &.noevents {
      border-bottom: none;
      padding: 20px 0;

      td {
        padding: 20px 0;
      }
    }
  }

  &__label {
    display: inline-block;
    min-width: 86px;
    text-align: right;
    font-weight: bold;
    margin-right: 4px;
  }

  &__btn {
    background-color: transparent;
    border: none;

    &:hover, &:focus {
      outline: none;
    }
  }

  &__text {
    &--red {
      background-color: transparent;
      border: none;
      color: $red;
      font-weight: bold;

      &:focus, &:hover {
        outline: none;
        text-decoration: underline;
      }
    }
    &:disabled {
      opacity: 0.1;
    }
  }

  &__link {
    background-color: transparent;
    border: none;
    color: $red;
    font-weight: bold;
    font-size: 12px;
    padding: 0;

    &:hover, &:focus {
      background-color: transparent;
      border: none;
      outline: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}