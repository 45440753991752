.login {
  &__wrapper {
    text-align: center;
    border: 1px solid $dark-blue;
    padding: 16px 0;
  }

  &__title {
    font-size: 16px;
    color: $black;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 12px;
    margin-bottom: 20px;

    &--red {
      color: $red;
    }
  }

  &__label {
    min-width: 108px;
    font-size: 12px;
    text-align: right;
    margin-right: 4px;
  }

  &__input {
    min-width: 70px;
    // style spinner boostrap
    .spinner-border-sm {
      height: 14px;
      width: 14px;
      border-width: 0.15em;
    }
  }

  &__input-wrapper {
    width: 262px;

    input {
      width: 100%;
      border: 1px solid $red;
      font-size: 11px;
    }

    button {
      font-size: 11px;
      border: 1px solid $red;
      margin-left: 4px;
    }
  }
}