.users-table {
  width: 100%;
  border-collapse: separate;
  box-sizing: border-box;

  th {
    background-color: $gray;
  }

  td {
    vertical-align: top;
  }

  &__label {
    display: inline-block;
    min-width: 86px;
    text-align: right;
    font-weight: bold;
    margin-right: 4px;
  }

  &__btn {
    background-color: transparent;
    border: none;
    color: $red;
    font-weight: bold;


    &:hover, &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  &__text {
    &--red {
      background-color: transparent;
      border: none;
      color: $red;
      font-weight: bold;

      &:focus, &:hover {
        outline: none;
        text-decoration: underline;
      }
    }
  }


}