.form {
  &--users {
    .form__label {
      min-width: 70px;
    }

    .form__input {
      width: 200px;
    }
  }
  &__section {
    border-bottom: 1px solid $gray;
    text-align: center;
    padding-bottom: 8px;
    margin-bottom: 10px;

    &:last-of-type {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 1em;
    }
  }

  &__title {
    background-color: $gray;
    font-weight: bold;
    text-align: left;
    padding: 0 4px;
    margin-bottom: 6px;
  }

  &__label {
    min-width: 180px;
    font-weight: bold;
    text-align: right;
    margin-right: 4px;
    margin-bottom: 6px;
  }

  &__input {
    max-height: 20px;
    width: 150px;
    border: 1px solid $red;
    margin-right: 4px;

    &--large {
      width: 600px;
    }

    &--file {
      width: auto;
      max-height: 25px;
      border: none;
      margin-bottom: 8px;
    }
  }

  &__radio {
    width: 150px;
  }

  &__textarea {
    width: 600px;
    height: 70px;
    margin-bottom: 8px;

    &--large {
      height: 200px;
    }
  }

  &__info {
    max-width: 600px;
  }

  &__attachment {
    color: $dark-red;
    margin-right: 4px;
  }

  &__btn {
    background-color: transparent;
    border: none;
    outline: none;
    color: $red;
    font-weight: bold;

    &:hover, &:focus {
      text-decoration: underline;
      outline: none;
    }
    // style spinner boostrap
    .spinner-border-sm {
      height: 14px;
      width: 14px;
      border-width: 0.15em;
    }
  }

  &__submit, &__button {
    border: 1px solid $red;
    // style spinner boostrap
    .spinner-border-sm {
      height: 14px;
      width: 14px;
      border-width: 0.15em;
    }
  }

  &__submit {
    text-transform: uppercase;
    &:hover, &:focus {
      outline: none;
    }
  }

  &__link {
    background-color: transparent;
    border: none;
    color: $red;
    font-weight: bold;
    font-size: 12px;
    padding: 0;

    &:hover, &:focus {
      background-color: transparent;
      border: none;
      outline: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__container {
    width: 600px;
  }
}
